<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Oscar David Trujillo Múnera                                   ###### -->
<!-- ###### @date: Marzo 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section>
    <v-card-title class="cardTitle">
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" persistent scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn small color="success" v-bind="attrs" v-on="on">
            <v-icon left>add_circle_outline</v-icon>
            Crear Medio
          </v-btn> -->
          <v-btn small class="ma-2" fab dark color="success" v-bind="attrs" v-on="on">
            <v-icon dark>
              add
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="blueGlobal">
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit" ref="form">
                <v-row class="ma-2">
                  <v-col cols="5">
                    <v-color-picker v-model="Color" hide-mode-switch swatches-max-height="100">
                    </v-color-picker>
                  </v-col>
                  <v-col cols="7">
                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <validation-provider v-slot="{ errors }" name="Nombre" rules="required">
                          <v-select class="white--text white b-r-3" v-model="bodegaSelected" :items="itemBodega"
                            item-text="nombreBodega" item-value="bodegasId.codigoBodega" return-object :error-messages="errors"
                            label="Bodega" tabindex="1" clearable required>
                          </v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider v-slot="{ errors }" name="Nombre" rules="required">
                          <v-text-field v-model="Nombre" :error-messages="errors" label="Nombre" tabindex="2" clearable
                            required></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea v-model="Descripcion" label="Descripción" tabindex="3" counter clearable
                          clear-icon="close"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="ma-2">
                  <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Alto" rules="required">
                      <v-text-field v-model="Alto" :error-messages="errors" label="Alto" tabindex="4" clearable
                        required></v-text-field>
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="Profundidad" rules="required">
                      <v-text-field v-model="Profundidad" :error-messages="errors" label="Profundidad" tabindex="5"
                        clearable required></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Ancho" rules="required">
                      <v-text-field v-model="Ancho" :error-messages="errors" label="Ancho" tabindex="7" clearable
                        required></v-text-field>
                    </validation-provider>
                    <v-text-field v-model="Volumen" label="Volumen" disabled></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions class="d-flex flex-row-reverse">
                  <v-btn class="ma-2" color="primary" type="submit" :disabled="invalid" @click="dialog = false"
                    tabindex="8">
                    Guardar
                  </v-btn>
                  <v-btn class="ma-2" text @click="reset" tabindex="6">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="dialog = false">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-data-table fixed-header height="700" :headers="headersMedios" :items="listarMedios.content"
      :page.sync="pageFront" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1"
      @page-count="pageCount = $event">
      <template v-slot:[`item.color`]="{ item }">
        <v-chip class="ma-2" color="#E0E0E0" text-color="#424242">
          <v-avatar center>
            <v-icon :title='item.color' v-bind:style="{
              color: item.color,
            }">archive</v-icon>
          </v-avatar>
        </v-chip>
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <span v-if="item.estado == '0'" class="green--text">LIBRE</span>
        <span v-if="item.estado == '1'" class="red--text">OCUPADO</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="blue" class="mr-2" @click="editMedio(item)">edit</v-icon>
        <v-icon small color="red" @click="deleteItem(item)">delete</v-icon>
      </template>
    </v-data-table>

    <v-card class="d-flex flex-row-reverse">
      <v-card class="pa-2" elevation="0">
        <v-row>
          <v-col cols="2" class="pa-2">
            <v-select small label="Cantidad" v-model="itemsPerPage" :items="items" item-text="number"
              item-value="numberValue"></v-select>
          </v-col>
          <v-col cols="10">
            <v-pagination small v-model="pageFront" :length="pagesCount" :total-visible="7"></v-pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </section>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  name: "Medios",
  newMedio: [],

  data: () => ({
    userRoles: {},
    roles: Role,
    dialog: false,
    dialogDelete: false,
    listarMedios: [],
    Id: -1,
    Color: "",
    Nombre: "",
    Descripcion: "",
    Alto: "",
    Ancho: "",
    Profundidad: "",
    buscar: "",
    pageFront: 1,
    pageBack: 0,
    pagesCount: 0,
    itemsPerPage: 10,
    itemBodega: [],
    bodegaSelected: 0,
    headersMedios: [
      {
        text: "Nombre",
        value: "nombre",
        align: "left",
        width: "15%",
      },
      {
        text: "Descripcion",
        value: "descripcion",
        align: "left",
        width: "30%",
        sortable: false,
      },
      {
        text: "Color",
        value: "color",
        align: "center",
        width: "10%",
        sortable: false,
      },
      {
        text: "Alto (cm)",
        value: "alto",
        align: "center",
        width: "9%",
        sortable: false,
      },
      {
        text: "Ancho (cm)",
        value: "ancho",
        align: "center",
        width: "9%",
        sortable: false,
      },
      {
        text: "Profundidad (cm)",
        value: "profundidad",
        align: "center",
        width: "9%",
        sortable: false,
      },
      {
        text: "Volumen (cm3)",
        value: "volumen",
        align: "center",
        width: "9%",
        sortable: false,
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
        width: "9%",
        sortable: false,
      },
      {
        text: "Bodega",
        value: "bodega.nombreBodega",
        align: "center",
        width: "9%",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        width: "9%",
        sortable: false,
      },
    ],
    items: [
      { number: "5", numberValue: 5 },
      { number: "10", numberValue: 10 },
      { number: "20", numberValue: 20 },
      { number: "50", numberValue: 50 },
      { number: "100", numberValue: 100 },
    ],
  }),

  created() {
    this.userRoles = this.auth.roles;
    this.listFirstPageMedios();
    this.$http
      .post("msa-pharmaceutical-services/listBodegas")
      .then((result) => {
        this.itemBodega = result.data;
        console.log(this.itemBodega)
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    Volumen: function () {
      return this.Alto * this.Ancho * this.Profundidad;
    },
    formTitle() {
      return this.Id === -1 ? "Crear Medio" : "Editar Medio";
    },
  },

  watch: {
    pageFront: function () {
      this.listWatcherChangePageOrAmountPerPage();
    },

    itemsPerPage: function () {
      this.listWatcherChangePageOrAmountPerPage();
    },
  },

  methods: {
    submit() {
      const nuevoMedio = {                
        nombre: this.Nombre,
        descripcion: this.Descripcion,
        color: this.Color,
        alto: this.Alto,
        ancho: this.Ancho,
        profundidad: this.Profundidad,
        volumen: this.Volumen,
        bodega: {
          bodegasId:this.bodegaSelected.bodegasId
        }
        ,empresa: {
          idEmpresa: this.enterprise.code
        }           
      };

      if(this.Id != -1){
        nuevoMedio['id'] = this.Id
      }

      console.log(nuevoMedio)

      this.$http
        .post("msa-pharmaceutical-services/mediosAdd", nuevoMedio)
        .then((result) => {
          console.log(
            "endpoint pharmaceutical services form send: " + result.data
          );
          this.listFirstPageMedios();
          this.reset();
        })
        .catch((error) => {
          console.log(error.toJSON());
        });
    },

    listFirstPageMedios() {
      this.$http
        .get("msa-pharmaceutical-services/listMediosPages")
        .then((result) => {
          console.log(result.data)
          this.listarMedios = result.data;
          this.pageFront = result.data.pageable.pageNumber + 1;
          this.pagesCount = result.data.totalPages;
        })
        .catch((error) => {
          console.log(error.toJSON());
        });
    },

    listWatcherChangePageOrAmountPerPage() {
      this.pageBack = this.pageFront - 1;
      this.$http
        .get(
          "msa-pharmaceutical-services/listMediosPages?page=" +
          this.pageBack +
          "&size=" +
          this.itemsPerPage
        )
        .then((result) => {
          this.listarMedios = result.data;
          this.pageFront = result.data.pageable.pageNumber + 1;
          this.pagesCount = result.data.totalPages;
        })
        .catch((error) => {
          console.log(error.toJSON());
        });
    },

    editMedio(item) {
      //this.reset();
      console.log(item);
      this.bodegaSelected = item.bodega
      this.Id = item.id;
      this.Nombre = item.nombre;
      this.Descripcion = item.descripcion;
      this.Color = item.color;
      this.Alto = item.alto;
      this.Ancho = item.ancho;
      this.Profundidad = item.profundidad;
      this.dialog = true;
      
    },

    reset() {
      this.bodegaSelected = 0;
      this.dialog = false;
      this.Id = -1;
      this.Nombre = "";
      this.Descripcion = "";
      this.Color = "";
      this.Alto = "";
      this.Ancho = "";
      this.Profundidad = "";
      this.$refs.observer.reset();
    },

    deleteItem() {
      this.dialogDelete = true;
    },

    ...mapMutations([ 
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy"]),
  },
};
</script>

<!-- #################################################################################### -->
<!-- ###### Script que hace referencia al js de vee-validate                       ###### -->
<!-- #################################################################################### -->
<codepen-resources lang="json">{
  "js": [
    "https://cdn.jsdelivr.net/npm/vee-validate@3.x/dist/vee-validate.js"
  ]
}</codepen-resources>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.blueGlobal {
  background: #0D47A1 !important;
  color: white;
}

.v-card__subtitle,
.v-card__text,
.cardTitle {
  padding: 0px;
}
</style>
